import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { buildDownloadPostAttachmentUrl } from 'state-domains/network';

import { sendFileDownloadRequest } from '../../../utils';

export const downloadPostAttachment = (postId: string, attachmentId: string): Observable<any> => {
    const url = buildDownloadPostAttachmentUrl(postId, attachmentId);
    return sendFileDownloadRequest(url).pipe(switchMap((blob: Blob) => observableOf(blob)));
};
